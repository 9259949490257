
import { defineComponent, onMounted } from "vue";
import KTCard from "@/components/cards/Card1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "projects",
  components: {
    KTCard,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Projects", ["Pages", "Profile"]);
    });

    const users1 = [
      { name: "Emma Smith", src: "/media/avatars/150-1.jpg" },
      { name: "Rudy Stone", src: "/media/avatars/150-2.jpg" },
      { name: "Susan Redwood", initials: "S", state: "primary" },
    ];

    const users2 = [
      { name: "Alan Warden", initials: "A", state: "warning" },
      { name: "Brian Cox", src: "/media/avatars/150-4.jpg" },
    ];

    const users3 = [
      { name: "Mad Masy", src: "/media/avatars/150-1.jpg" },
      { name: "Cris Willson", src: "/media/avatars/150-2.jpg" },
      { name: "Mike Garcie", initials: "M", state: "info" },
    ];

    const users4 = [
      { name: "Nich Warden", initials: "N", state: "warning" },
      { name: "Rob Otto", initials: "R", state: "success" },
    ];

    const users5 = [
      { name: "Francis Mitcham", src: "/media/avatars/150-5.jpg" },
      { name: "Michelle Swanston", src: "/media/avatars/150-13.jpg" },
      { name: "Susan Redwood", initials: "S", state: "primary" },
    ];

    const users6 = [
      { name: "Emma Smith", src: "/media/avatars/150-1.jpg" },
      { name: "Rudy Stone", src: "/media/avatars/150-2.jpg" },
      { name: "Susan Redwood", initials: "S", state: "primary" },
    ];

    const users7 = [
      { name: "Meloday Macy", src: "/media/avatars/150-3.jpg" },
      { name: "Rabbin Watterman", initials: "S", state: "success" },
    ];

    const users8 = [
      { name: "Emma Smith", src: "/media/avatars/150-1.jpg" },
      { name: "Rudy Stone", src: "/media/avatars/150-2.jpg" },
      { name: "Susan Redwood", initials: "S", state: "primary" },
    ];

    const users9 = [
      { name: "Meloday Macy", src: "/media/avatars/150-3.jpg" },
      { name: "Rabbin Watterman", initials: "S", state: "danger" },
    ];

    return {
      users1,
      users2,
      users3,
      users4,
      users5,
      users6,
      users7,
      users8,
      users9,
    };
  },
});
